@mixin mq($range) {
    $mobile: 320px;
    $tablet: 768px;
    $desktop: 1280px;
    @if $range == mobile-only {
      @media (max-width: #{$tablet - 0.02}) {
        @content;
      }
    } @else if $range == mobile {
      @media (min-width: $mobile) {
        @content;
      }
    } @else if $range == mobile-medium {
      @media (min-width: 400px) {
        @content;
      }
    } @else if $range == mobile-big {
      @media (max-width: 460px) {
        @content;
      }
    } @else if $range == tablet-only {
      @media (min-width: $tablet) and (max-width: #{$desktop - 0.02}) {
        @content;
      }
    } @else if $range == tablet {
      @media (min-width: $tablet) {
        @content;
      }
    } @else if $range == desktop {
      @media (min-width: $desktop) {
        @content;
      }
    } @else if $range == notDesktop {
      @media (max-width: #{$desktop - 0.02}) {
        @content;
      }
    } @else if $range == retina {
      @media screen and (min-device-pixel-ratio: 2),
        screen and (-moz-min-device-pixel-ratio: 2),
        screen and (-o-min-device-pixel-ratio: 2/1),
        screen and (-webkit-min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
        @content;
      }
    }
  }
  @mixin fontStyle($fw: 400, $lh: 1.5, $ls: 0.01em) {
    font-weight: $fw;
    line-height: $lh;
    letter-spacing: $ls;
  }