.icon {
  width: 40px;
  height: 25px;
  border: 1.5px solid var(--font-brown-light-2);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  text-decoration: none;
  font-family: "CopperplateCyrLight";
  font-size: 18px;
  // line-height: 1.2 !important;
  font-weight: 400;
  color: var(--font-brown);
  // background-color: #efebe9;
  transition: 1s;  
}

.icon-PL:hover {
  color: var(--hover-main);
  border: 1.5px solid var(--hover-main);
}

.icon-UA:hover {
  color: var(--hover-main);
  border: 1.5px solid var(--hover-main);
}

@media screen and (max-width: 992px) {
  .icon {
    font-size: 20px;
  }
}

.icon_p {
  display: block;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 0;
  
}
