/* HEADER */

header {
  padding-top: 10px;
  // background-color: var(--bs-light);

  // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  //   0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.menu-wrapper {
  display: flex;
  justify-content: flex-end;
}

// .yello {
//   color: #fdfd04;
//   font-weight: 700;
//   // -webkit-text-stroke: 1px rgb(136, 112, 252);
// }
.nav-link {
  margin-right: 20px;
  //   text-decoration: none;
  //   // font-family: var(--monserrat);
  //  font-size: 20px;
  //  letter-spacing: 0.5px;
  //  color: var(--bs-gray-700);
  //  font-weight: 400;
}

.me-50 {
  margin-right: 50px;
}

// .nav-link-right {
//   margin-right: 30px;
// }
.bold {
  font-weight: 700;
}
.logo {
  // background-color: var(--color3);
  padding: 0px 5px 0px 10px !important;
  margin-right: 0 !important;
  font-size: 20px;
  align-items: end;
  // opacity: 0.5;
  // border-radius: 5px;
  transition: all 0.2s ease;
}
// .logo:hover {
//   opacity: 1;
// }
.nav {
  // color: var(--font-black-color);
  padding:  0 10px 10px 10px;
}

// .nav > li > a,
.dropdown > button {
  // font-family: var(--monserrat);
  font-size: 20px;
  letter-spacing: 0.5px;
  color: var(--bs-gray-700);
  font-weight: 400;
  border: none;
  background-color: #fff;
}

.mr-50 {
  margin-right: 50px;
}

.dropdown-item {
  width: 30px;
  height: auto;
 
  margin-bottom: 5px;
  // background-color: var(--main-background-color) !important;
}

.lang-item {
   padding: 0 !important;
}

.dropdown-menu {
  min-width: 0 !important;
  padding: 5px;
  border: none !important;
}

.portfolio-menu {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
}

.lang {
  text-decoration: none;
}

.lng {
  margin-top: 10px;
  // text-decoration: none;
}

// .navbar {
//     padding: 15px 0;
//     margin-bottom: 30px;
//     box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
//     0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

// }

// .row {
//     display: flex;
//    align-items: center;
//      list-style: none;
// //    padding-left: 50px;
// }

.lang-menu {
  height: 26px !important;
  margin: 0 !important;
  padding: 0 !important;
  text-decoration: none;
  background-color: #efebe9;
  // margin: 0 0 -5px !important;
  // box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
  //   0px 2px 1px -1px rgb(0 0 0 / 12%);
}

// .link {
//   text-decoration: none;  
//   font-size: 20px;
//   letter-spacing: 0.5px;
//   color: var(--bs-gray-700);
//   font-weight: 400;
// }

.home-icon {
  stroke: var(--bs-gray-600);
  fill: var(--bs-gray-600);
}
.icon-wrapper:hover .home-icon {
  stroke: #2158ca;
  fill: #2158ca;
}

a::after {
  color: var(--bs-gray-600);
}

// .navbar-menu__lang {
//   display: flex;
// }

@media screen and (max-width: 992px) {
  .navbar_link__9Wpqp {
    font-weight: 700 !important;
  }
}
