@import "../../shared/styles/sass/main.scss";

/* Loader Styles Below */
.ldsDualRing {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.ldsDualRing:after {
  position: absolute;
  content: "";
  display: block;
  top: 44%;
  left: 38.5%;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid rgb(0, 0, 0);
  border-color: rgb(81, 0, 255) transparent rgb(255, 213, 0) transparent;
  animation: ldsDualRing 1.2s linear infinite;

  @include mq(mobile-medium) {
    left: 43%;
  }
  @include mq(tablet) {
    top: 43%;
    left: 44.5%;
    width: 90px;
    height: 90px;
    border: 8px solid rgb(0, 0, 0);
    border-color: rgb(81, 0, 255) transparent rgb(255, 213, 0) transparent;
  }
  @include mq(desktop) {
    top: 40%;
    left: 47%;
  }
}
@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}