.link {
  display: block;
  width: 100%;
  height: 100%;
  // padding: 5px 0;
  // display: inline-block;
  text-decoration: none;
  // font-family: var(--monserrat);
  font-family: "CopperplateCyrLight";
  font-size: 20px;
  letter-spacing: 0.5px;
  color: var(--font-brown);
  font-weight: 400;
  transition: 0.5s;
}

.linkActive {
  width: 100%;
  height: 100%;
  padding: 5px 0;
  text-decoration: none;
  // font-family: var(--monserrat);
  font-family: "CopperplateCyrLight";
  font-size: 20px;
  letter-spacing: 0.5px; 
  color: var(--font-brown);
  font-weight: 400;
  position: relative;
  transition: 0.5s;
}

@media screen and (max-width: 992px) {
  .link,
  .linkActive {
    font-size: 18px;    
  }
}



.link:hover,
.linkActive:hover {
  color: var(--hover-main);
}

.linkActive:visited {
  color: var(--font-brown);
}

// .linkActive:hover {
// color: var(--hover-main);
// }

.linkActive::after {
  content: '';

  position: absolute;
  left: 25%;
  // bottom: -5;

  display: block;
  width: 50%;
  height: 2px;
  border-radius: 1px;
  background-color: var(--font-brown-light-2);
  // opacity: .7;
}
