.LogoBox {
  display: flex;
  // background-image: url( '../../shared/icons/photo-outline.svg' );
  background-size: 120px;
  background-position: 42% 5%;
  background-repeat: no-repeat;
  padding: 20px 0;
}

// .Logo_Img {
//   max-height: 67px;
// }

.Logo {
  line-height: 1.2;
  //   font-family: var(--monserrat);
  //   font-family:var(--bs-font-sans-serif);
  //   font-family: 'Dancing Script', cursive;
}

.Logo_name {
  //   font-family: 'Open Sans Light', sans-serif;
  font-family: "CopperplateGothicBoldCyr";
  margin: 0;
  font-stretch: 50%;
  // color: #4b5085;
  color: var(--font-brown-light-2);
  // color: var(--bs-gray-900);
  font-weight: 300;
  letter-spacing: 1.5px;
  font-size: 22px;
  opacity: 1;
  // text-transform: uppercase;
  transition: 0.5s;
  // text-shadow: 1px -2px 0px #dbd0ba;
  // text-shadow: 1px 0px 0px var(--yellow);
}

.Logo_about {
  // display: flex;
  font-family: "CopperplateCyrLight";
  // font-family: var(--monserrat);
  color: #bc5418;  
  font-size: 20px;
  // text-transform: uppercase;
  // justify-content: space-between;
  letter-spacing: 10.6px;
  font-weight: 600;
  //   font-family: 'Pacifico', cursive;
  // font-style: italic;
  margin: 0;
}

@media screen and (min-width: 575px) {
  .Logo_name {
    font-weight: 300;
    letter-spacing: 1.5px;
    font-size: 24px;
    // opacity: .9;
  }
  .Logo_about {
    font-size: 22px;
    letter-spacing: 11.4px;
  }
}
@media screen and (min-width: 992px) {
  .Logo_name {
    font-weight: 300;
    letter-spacing: 1.5px;
    font-size: 26px;
    // opacity: .8;
  }
  .Logo_about {
    font-size: 24px;
    letter-spacing: 12.2px;
  }
}

.Logo:hover .Logo_name {
  color: var(--hover-main);
  opacity: .7;
}

// .Logo_about_Wrapper {
//   background-color:#fff;
//   border: 1.5px solid var(--font-brown-light-2);
//   border-radius: 5px;
// }


