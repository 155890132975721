/* Підвал */
footer {
  padding: 5px 0;
  margin-top: 10px;
  background-color: var(--bs-light);;
}

.footer_wrapper {
  display: flex;
  justify-content: space-between;
}

.footer_icon-wrapper {
  display: flex;
  justify-content: flex-start; 
}

.footer_copyright-wrapper {
  display: flex;
  font-family: "CopperplateCyrLight";
}

.footer_copyright-wrapper p {
  margin: 0;
  font-size: 12px;
}

.f_copyright-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 1px;
  margin-right: 5px;
}

.f_p-wrapper {
  color: var(--font-brown) ;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.btn-footer {
  display: block;  
  padding: 0 20px; 
  outline: none; 
  text-align: center;
  letter-spacing: 0.04em; 
  background: inherit; 
  cursor: pointer;
}

.icon-instagram,
.icon-facebook {
  stroke: var(--font-brown);
  fill: var(--font-brown);
  transition: 1s;
}

.btn-footer:hover .icon-instagram {
  stroke: var(--hover-main);
  fill: var(--hover-main);  
}

.btn-footer:hover .icon-facebook {
  stroke: var(--hover-second);
  fill: var(--hover-second);  
}
