.container {
    max-width: 320px;
    padding: 0 20px;
  
    @include mq(tablet) {
      max-width: 768px;
      padding: 0 32px;
    }
    @include mq(desktop) {
      max-width: 1024px;
      padding: 0 16px;
    }
  }